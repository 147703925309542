<template>
  <v-app >
    <cabecera/>
    <v-main style="background: #eef5f9"
        >
  <v-container    >
    <v-row class="text-center">
      <v-col cols="12">
        <section id="eventos">
          <v-row  class="text-center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <h1 class="display-1 font-weight-bold">Eventos</h1>
            </v-col>
          </v-row>

          <v-row justify="center" >

            <v-col cols="12" lg="3" sm="3" v-for="fiesta in fiestas" :key="fiesta.id" >
              <router-link :to="{ name: 'evento', params: { tag: fiesta.tag }}">
                <v-card v-if="fiesta.activo" elevation="10">
                <v-card-title  justify="center" ><h1 >{{fiesta.nombre}}</h1></v-card-title>
                <v-card-text style=" height:280px">
                  <v-row justify="center">
                    <v-col cols="12" lg="6" md="6" sm="12">

                      <div v-if="fiesta.foto==''  ">
                        <v-img max-height="200" min-height="200"  contain src="@/assets/sinimagen.jpeg" />
                      </div>

                      <div v-if=" fiesta.foto!==''">
                        <v-img max-height="200" min-height="200" contain :src="fiesta.foto"></v-img>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row justify="center" >
                    <v-col cols="12" lg="12" align="center" >
                      <h3>  {{formatDatee(fiesta.fechaInicio)}}</h3>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card></router-link>
            </v-col>
          </v-row>
        </section>


      </v-col>
    </v-row>
  </v-container></v-main>
    <piepagina/>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-app>
</template>

<script>
import axios from 'axios';

import cabecera from './general/cabecera'
import piepagina from './general/piepagina';
import moment from "moment-timezone";

  export default {
    name: 'Inicio',
    components: {
      cabecera,
      piepagina,

    },
    data ()  {
      return {
        fiestas:[],
        overlay:false,
      }
    },
    mounted() {
      this.traeEventos()
    },
    methods:{
      formatDatee(value) {
        return moment(value).locale('es-pe').format("DD MMMM YYYY")
      },
      traeEventos(){
        this.overlay = true;
        axios.get("eventos/cliente/6", {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          }
        })
            .then((response)  => {
              this.fiestas = response.data;
              this.overlay = false;
            })
            .catch((error)  => {
              console.error(error);
              this.overlay = false;
            });
    }
    }
  }
</script>
